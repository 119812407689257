import {Link} from "gatsby";
import * as React from 'react';
import CheckMark from './Icons/CheckMark';
import * as styles from './GreenBlock.module.scss';
import LinkArrowGreen from './Icons/LinkArrowGreen';
import useWindowSize from '../hooks/window-size';
import {getImage, getPageUrl} from "../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import { Parallax } from "react-scroll-parallax";

const GreenBlock = ({title, body, page, image, elements}) => {
    const windowSize = useWindowSize();
    const paralaxValue = 5
    const responsiveValue = windowSize.width >= 769 ? paralaxValue : 0;

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.column}>
                    <div className={styles.textContainer}>
                        <div>
                            <h2 className={styles.title}>{title}</h2>
                            <div className={styles.description}>
                                {renderRichText(body)}
                            </div>
                        </div>
                        <Link to={getPageUrl(page)}>
                            <LinkArrowGreen
                                size={windowSize.width >= 1024 ? 'large' : 'small'}
                            />
                        </Link>
                    </div>
                    <div className={styles.listContainer}>
                        <ul className={styles.list}>
                            {elements.map((element, index) => {
                                return (
                                    <li key={index} className={styles.item}><CheckMark/> {element.title}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.imageContainer}>
                    <Parallax speed={responsiveValue}>
                        <img
                            className={styles.imageContainer}
                            src={getImage(image).url}
                            alt={getImage(image).title}
                        />
                        </Parallax>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GreenBlock;
