import * as React from 'react';
import * as styles from './Partners.module.scss';
import {getImage} from "../contentful/data-provider";

const Partners = ({partnerLogos}) => {
    return (
        <div className={styles.container}>
            <div className={styles.list}>
                {partnerLogos.map((partnerLogo, index) => {
                    return (
                        <div className={styles.imageContainer} key={index}>
                            <img
                                className={styles.image}
                                src={getImage(partnerLogo).url}
                                alt={getImage(partnerLogo).title}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default Partners;