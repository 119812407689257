import { Link } from "gatsby";
import * as React from "react";
import LinkArrow from "./Icons/LinkArrow";
import * as styles from "./SinglePair.module.scss";
import { getImage, getPageUrl } from "../contentful/data-provider";
import useWindowSize from "../hooks/window-size";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Parallax } from "react-scroll-parallax";

const SinglePair = ({ title, subtitle, image, body, page }) => {
    const windowSize = useWindowSize();
    const paralaxValue = 30
    const responsiveValue = windowSize.width >= 769 ? paralaxValue : 0;

	return (
		<div className={styles.container}>
			<div className={styles.imageContainer}>
				<Parallax translateY={[0, responsiveValue]}>
					<img className={styles.image} src={getImage(image).url} alt={getImage(image).title} />
				</Parallax>
			</div>
			<div className={styles.textContainer}>
				<div className={styles.header}>
					<div className={styles.label}>{subtitle}</div>
					<div className={styles.link}>
						<Link to={getPageUrl(page)}>
							<LinkArrow className={styles.arrow} size={windowSize.width >= 769 ? "large" : "small"} />
						</Link>
					</div>
				</div>
				<h2 className={styles.title}>{title}</h2>
				<div className={styles.description}>{renderRichText(body)}</div>
			</div>
		</div>
	);
};

export default SinglePair;
