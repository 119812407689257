import {graphql} from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Partners from '../components/Partners';
import PageHeader from '../components/PageHeader';
import PageTitle from '../components/PageTitle';
import SinglePair from '../components/SinglePair';
import GreenBlock from '../components/GreenBlock';
import ViewAllLink from '../components/ViewAllLink';
import OffsetSolid from '../components/OffsetSolid';
import FullWidthImage from '../components/FullWidthImage';
import ColumnHoverLinks from '../components/ColumnHoverLinks';
import {getImage, getPageUrl} from "../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import TripleOffsetImages from '../components/TripleOffsetImages';
import HorizontalOffsetGrid from '../components/HorizontalOffsetGrid';
import * as homePageHeaderSecondary from "../components/HomePageHeaderSecondary.module.scss";
import HomePageSolutionsHeaderSecondary from '../components/HomePageSolutionsHeaderSecondary';
import CardsList from "../components/CardsList";


const IndexPage = ({data}) => {
    const {contentfulIndexPageSection, allContentfulPost} = data;
    const index = contentfulIndexPageSection;
    const replaceForBlueText = (text) => {
        return text.replace(/(<!)+/g, '<span>').replace(/(!>)+/g, '</span>');
    }
    return (
        <Layout>
            <main>
                <PageHeader hasBottomMargin>
                    <PageTitle>
                        <div>
                            <div>{index.headerSubtitle}</div>
                        </div>
                        <h1 dangerouslySetInnerHTML={{__html: replaceForBlueText(index.headerTitle)}}/>
                    </PageTitle>
                    <div className={homePageHeaderSecondary.container}>
                        <ViewAllLink label={index.headerCtaText} url={`${getPageUrl(index.headerCtaUrl)}`}/>
                        <div className={homePageHeaderSecondary.description}>
                            {renderRichText(index.headerBody)}
                        </div>
                    </div>
                </PageHeader>
                <Section>
                    <TripleOffsetImages
                        leftSrc={index.headerImages.length > 0 && index.headerImages[0].url}
                        centerSrc={index.headerImages.length > 1 && index.headerImages[1].url}
                        rightSrc={index.headerImages.length > 2 && index.headerImages[2].url}
                    />
                </Section>
                <PageHeader>
                    <PageTitle>
                        <h2 dangerouslySetInnerHTML={{__html: replaceForBlueText(index.productsTitle)}}/>
                    </PageTitle>
                    <HomePageSolutionsHeaderSecondary subtitle={index.productsSubtitle} body={index.productsBody}/>
                </PageHeader>
                <Section hasMaxWidth isHorizontalOffsetGrid>
                    <HorizontalOffsetGrid products={index.productElements}/>
                </Section>
                <Section hasMaxWidth isColumnHoverLinks>
                    <ColumnHoverLinks
                        productGroups={index.solutionElements}
                        body={index.solutionsBody}
                        image={index.solutionsImage}
                    />
                </Section>
                <Section hasMaxWidth isSinglePair>
                    <SinglePair
                        title={index.technologyTitle}
                        subtitle={index.technologySubtitle}
                        body={index.technologyBody}
                        image={index.technologyImage}
                        page={index.technologyUrl}
                    />
                </Section>
                <GreenBlock
                    title={index.greenTitle}
                    body={index.greenBody}
                    page={index.greenUrl}
                    elements={index.greenElements}
                    image={index.greenImage}
                />
                <Section>
                    <FullWidthImage
                        src={getImage(index.aboutImage).url}
                        textBlock={
                            <OffsetSolid
                                title={index.aboutTitle} body={index.aboutBody}
                                page={index.aboutUrl} isLink isTop
                            />
                        }
                    />
                </Section>
                <PageHeader isPressSectionHeader>
                    <PageTitle>
                        <h2>{index.pressTitle}</h2>
                    </PageTitle>
                    <ViewAllLink label={index.pressCtaText} url={getPageUrl(index.pressCtaUrl)}/>
                </PageHeader>
                <Section hasMaxWidth isPressSection hasBorderBottom>
                    {<CardsList items={allContentfulPost.nodes}/>}
                </Section>
                <Partners partnerLogos={index.partnerLogoImages}/>
            </main>
        </Layout>
    )
}

export default IndexPage;

export function Head({data}) {
    const {contentfulIndexPageSection} = data;
    return (
        <title>Derschlag - {contentfulIndexPageSection.headerTitle.replace(/(<!)+/g, '').replace(/(!>)+/g, '')}</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulIndexPageSection(contentful_id: {eq: "1kQAySdgldLyE9x9jABgMb"}, node_locale: {eq: $locale}) {
            headerSubtitle
            headerTitle
            headerCtaText
            headerCtaUrl {
                ... PageUrlFields
            }
            headerBody {
                raw
            }
            headerImages {
                ... ImageFields
            }
            productsTitle
            productsSubtitle {
                raw
            }
            productsBody {
                raw
            }
            productElements {
                ... ProductFields
            }
            solutionsImage {
                ... ImageFields
            }
            solutionsBody {
                raw
            }
            solutionElements {
                ... ProductGroupFields
            }
            technologyUrl {
                ... PageUrlFields
            }
            technologySubtitle
            technologyTitle
            technologyBody {
                raw
            }
            technologyImage {
                ... ImageFields
            }
            greenTitle
            greenUrl {
                ... PageUrlFields
            }
            greenBody {
                raw
            }
            greenElements {
                ... TitleElementFields
            }
            greenImage {
                ... ImageFields
            }
            aboutTitle
            aboutUrl {
                ... PageUrlFields
            }
            aboutBody {
                raw
            }
            aboutImage {
                ... ImageFields
            }
            pressTitle
            pressCtaText
            pressCtaUrl {
                ... PageUrlFields
            }
            partnerLogoImages {
                ... ImageFields
            }
        }
        allContentfulPost(filter: {node_locale: {eq: $locale}}, limit: 2, sort: {fields: publishedAt, order: DESC}) {
            nodes {
                ... PostFields
            }
        }
    }
`;