import { Link } from "gatsby";
import * as React from "react";
import LinkArrow from "./Icons/LinkArrow";
import * as styles from "./CardsList.module.scss";
import { getImage, getPostUrl } from "../contentful/data-provider";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Parallax } from "react-scroll-parallax";
import useWindowSize from "../hooks/window-size";

const CardsList = ({ items }) => {
	const windowSize = useWindowSize();
	const paralaxValue = 3;
	const responsiveValue = windowSize.width >= 769 ? paralaxValue : 0;

	const renderItems = () => {
		return items.map((item, index) => {
			return (
				<li className={styles.item} key={index}>
					<div className={styles.infoContainer}>
						<div className={styles.date}>{item.publishedAt}</div>
						<h2 className={styles.title}>{item.title}</h2>
						<div className={styles.description}>{item.excerpt && renderRichText(item.excerpt)}</div>
					</div>
					<Parallax speed={responsiveValue}>
						<div className={styles.imageContainer}>
							<Link to={getPostUrl(item)}>
								<LinkArrow size='large' />
								<img className={styles.image} src={getImage(item.image).url} alt={getImage(item.image).title} />
							</Link>
						</div>
					</Parallax>
				</li>
			);
		});
	};

	return (
		<div className={styles.container}>
			<ul className={styles.list}>{renderItems()}</ul>
		</div>
	);
};

export default CardsList;
