import { Link } from "gatsby";
import * as React from "react";
import * as styles from "./ColumnHoverLinks.module.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { getImage } from "../contentful/data-provider";
import { Parallax } from "react-scroll-parallax";
import useWindowSize from "../hooks/window-size";

const ColumnHoverLinks = ({ productGroups, body, image }) => {
    const windowSize = useWindowSize();
    const paralaxValue = 7
    const responsiveValue = windowSize.width >= 769 ? paralaxValue : 0;
    
	const renderSvg = () => {
		return (
			<svg className={styles.icon} xmlns='http://www.w3.org/2000/svg' width='40' height='41' viewBox='0 0 40 41'>
				<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
					<g transform='translate(-290 -3248)'>
						<g transform='translate(0 2814)'>
							<g transform='translate(45 434.25)'>
								<g transform='translate(245)'>
									<rect width='40' height='40' x='0' y='0' fill='#FFF' rx='20'></rect>
									<g stroke='#000' strokeWidth='1.5' transform='translate(10.052 10.266)'>
										<g transform='rotate(45 4.92 11.88)'>
											<path strokeLinejoin='round' d='M11.772 1.994L2.145 11.621' transform='rotate(-45 6.958 6.807)'></path>
											<path
												d='M11.4586321 11.8789505L11.8793788 2.03835654 2.03792833 2.4582468'
												transform='rotate(-45 6.959 6.959)'
											></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.inner}>
				<div className={styles.imageContainer}>
					<Parallax speed={responsiveValue}>
						<img className={styles.image} src={`${getImage(image).url}`} alt={`${getImage(image).title}`} />
					</Parallax>
					<div className={styles.offsetBox}>{renderRichText(body)}</div>
				</div>
				<ul className={styles.list}>
					{productGroups.map((productGroup, index) => {
						return (
							<li className={styles.item} key={index}>
								<Link className={styles.link} to={productGroup.node_locale === 'de' ? productGroup.slug : '/en' + productGroup.slug}>
									<div className={styles.textContainer}>
										<h4 className={styles.title}>{productGroup.title}</h4>
										<div className={styles.description}>{renderRichText(productGroup.body)}</div>
									</div>
									{renderSvg()}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default ColumnHoverLinks;
