import React from "react";

const ViewAllArrow = (props) => {
	return (
		<>
			{props.size === "small" && (
				<svg width='40' height='40' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 41">
        <g transform="translate(0 .25)" fill="none" fill-rule="evenodd">
          <rect fill="#FFF" width="40" height="40" rx="20"/>
          <g stroke="#0E4C8B" stroke-width="1.5">
            <path stroke-linejoin="round" d="m24.814 15.187-9.627 9.627"/>
            <path d="m24.394 25.028.42-9.841-9.841.42"/>
          </g>
        </g>
      </svg>
			)}

			{props.size === "large" && (
				<svg xmlns='http://www.w3.org/2000/svg' width='53' height='53' viewBox='0 0 53 53'>
					<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
						<g transform='translate(-1427 -6004)'>
							<g transform='translate(204 5971)'>
								<g transform='translate(969 33)'>
									<g transform='translate(254)'>
										<rect width='53' height='53' x='0' y='0' fill='#FFF' rx='26.5'></rect>
										<g stroke='#0E4C8B' strokeWidth='1.5' transform='translate(13.32 13.603)'>
											<g transform='rotate(45 6.52 15.74)'>
												<path strokeLinejoin='round' d='M15.597 2.642L2.842 15.398' transform='rotate(-45 9.22 9.02)'></path>
												<path
													d='M15.1826875 15.7396095L15.7401768 2.70082242 2.70025503 3.25717701'
													transform='rotate(-45 9.22 9.22)'
												></path>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)}
		</>
	);
};

export default ViewAllArrow;
