import * as React from 'react';
import * as styles from './HomePageSolutionsHeaderSecondary.module.scss';
import {renderRichText} from "gatsby-source-contentful/rich-text";

const HomePageSolutionsHeaderSecondary = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.description}>
                {renderRichText(props.subtitle)}
            </div>
            <div className={styles.description}>
                {renderRichText(props.body)}
            </div>
        </div>
    )
};

export default HomePageSolutionsHeaderSecondary;