import React from "react";

const LinkArrowGreen = (props) => {
	return (
		<>
			{props.size === 'small' && (
				<svg width='40' height='40' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'>
					<g fill='none' fill-rule='evenodd'>
						<rect fill='#FFF' width='40' height='40' rx='20' />
						<g stroke='#0B5132' stroke-width='1.5'>
							<path stroke-linejoin='round' d='m24.814 15.187-9.627 9.627' />
							<path d='m24.394 25.028.42-9.841-9.841.42' />
						</g>
					</g>
				</svg>
			)}

      {props.size === 'large' && (
				<svg xmlns='http://www.w3.org/2000/svg' width='66' height='66' viewBox='0 0 66 66'>
					<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
						<g transform='translate(-690 -4394)'>
							<g transform='translate(0 3579)'>
								<path fill='#D7EAE1' d='M0 650H1680V1805H0z'></path>
								<g transform='translate(200 750)'>
									<g transform='translate(490 65)'>
										<rect width='66' height='66' x='0' y='0' fill='#FFF' rx='33'></rect>
										<g stroke='#0B5132' strokeWidth='1.5' transform='translate(16.586 16.94)'>
											<g transform='rotate(45 8.119 19.6)'>
												<path
													strokeLinejoin='round'
													d='M19.423 3.29L3.539 19.174'
													transform='rotate(-45 11.481 11.232)'
												></path>
												<path
													d='M18.9067429 19.6002684L19.6009749 3.36328829 3.36258174 4.05610722'
													transform='rotate(-45 11.482 11.482)'
												></path>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)}
		</>
	);
};

export default LinkArrowGreen;
