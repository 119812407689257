// extracted by mini-css-extract-plugin
export var container = "ColumnHoverLinks-module--container--b7f17";
export var description = "ColumnHoverLinks-module--description--c1341";
export var icon = "ColumnHoverLinks-module--icon--4f4a7";
export var image = "ColumnHoverLinks-module--image--ce818";
export var imageContainer = "ColumnHoverLinks-module--imageContainer--fbaf4";
export var inner = "ColumnHoverLinks-module--inner--fc6ae";
export var item = "ColumnHoverLinks-module--item--5af7b";
export var link = "ColumnHoverLinks-module--link--813c9";
export var list = "ColumnHoverLinks-module--list--dbb12";
export var offsetBox = "ColumnHoverLinks-module--offsetBox--b9879";
export var textContainer = "ColumnHoverLinks-module--textContainer--6812f";
export var title = "ColumnHoverLinks-module--title--bf1e4";