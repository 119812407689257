import * as React from "react";
import * as styles from "./ViewAllLink.module.scss";
import ViewAllArrow from "./Icons/ViewAllArrow";
import useWindowSize from "../hooks/window-size";
import { Link } from "gatsby";

const ViewAllLink = ({ label, url }) => {
	const windowSize = useWindowSize();
	return (
		<div className={styles.container}>
			<Link to={url} className={styles.link}>
				{label} <ViewAllArrow size={windowSize.width >= 769 ? "large" : "small"} />
			</Link>
		</div>
	);
};

export default ViewAllLink;
