import React from 'react';

function CheckMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-200 -4628)">
          <g transform="translate(0 3579)">
            <path d="M0 650H1680V1805H0z"></path>
            <g transform="translate(200 750)">
              <g transform="translate(0 279)">
                <g transform="translate(0 20)">
                  <rect
                    width="29"
                    height="29"
                    x="0"
                    y="0"
                    fill="#47EFA5"
                    rx="14.4"
                  ></rect>
                  <path
                    stroke="#0B5132"
                    strokeWidth="1.5"
                    d="M9.66666667 14.8164727L13.6309598 18.1152137 19.5765032 10.4722222"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckMark;
