// extracted by mini-css-extract-plugin
export var column = "GreenBlock-module--column--eef89";
export var container = "GreenBlock-module--container--fe13e";
export var description = "GreenBlock-module--description--c219c";
export var image = "GreenBlock-module--image--7b3bd";
export var imageContainer = "GreenBlock-module--imageContainer--44b43";
export var inner = "GreenBlock-module--inner--31271";
export var item = "GreenBlock-module--item--ef028";
export var list = "GreenBlock-module--list--abb96";
export var textContainer = "GreenBlock-module--textContainer--02a02";
export var title = "GreenBlock-module--title--02935";