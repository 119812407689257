import * as React from 'react';
import {getImage, getProductUrl} from "../contentful/data-provider";
import * as styles from './HorizontalOffsetGrid.module.scss';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {Link} from "gatsby";

const HorizontalOffsetGrid = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <ul className={styles.list}>
                    {props.products.map((product, index) => {
                        return (
                                <li
                                    className={styles.item}
                                    key={index}
                                >
                                    <Link to={getProductUrl(product)}>
                                    <div className={styles.iconContainer}>
                                        <img
                                            src={getImage(product.illustration).url}
                                            alt={getImage(product.illustration).title}
                                        />
                                    </div>
                                    <h3 className={styles.title}>{product.title}</h3>
                                    <div className={styles.description}>{renderRichText(product.body)}</div>

                                    </Link>
                                </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
};

export default HorizontalOffsetGrid;
